<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="
        this.type === 'view'
          ? '法人组织管理查看'
          : this.type === 'add'
          ? '法人组织管理新增'
          : '法人组织管理编辑'
      "
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <form-layout
      ref="formLayout"
      :column="formColumn"
      :dataForm="dataForm"
    ></form-layout>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import { submit, tree } from "@/api/sync/corpnOrg";

export default {
  components: {
    FormLayout,
    HeadLayout,
  },
  data() {
    return {
      type: "view",
      dataForm: {},
      submitLoading: false,
    };
  },
  computed: {
    headBtnOptions() {
      let result = [];
      if (["add", "edit"].includes(this.type)) {
        result.push({
          label: this.$t("cip.cmn.btn.saveBtn"), //保存
          emit: "head-save",
          btnOptType: "save",
          loading: this.submitLoading,
        });
        result.push({
          label: this.$t("cip.cmn.btn.saveBackBtn"), //保存并返回
          emit: "head-save-cancel",
          loading: this.submitLoading,
        });
      }
      result.push({
        label: this.$t("cip.cmn.btn.celBtn"), //取消
        emit: "head-cancel",
      });
      return result;
    },
    formColumn() {
      return [
        {
          label: "名称",
          prop: "name",
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t("cip.cmn.rule.inputWarning") + "名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "单位简称",
          prop: "shortName",
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["add", "edit"].includes(this.type),
        },
        {
          label: "英文全称",
          prop: "enName",
          span: 8,
          maxlength: 200,
          showWordLimit: true,
          disabled: !["add", "edit"].includes(this.type),
          rules: [
            {
              required: false,
              validator: this.validateEnName,
              trigger: "blur",
            },
          ],
        },
        {
          label: "编码",
          prop: "code",
          span: 8,
          maxlength: 20,
          showWordLimit: true,
          disabled: !["add"].includes(this.type),
          rules: [
            {
              required: true,
              message: "请输入编码",
              trigger: "blur",
            },
          ],
        },
        {
          label: "类型名称",
          prop: "typeName",
          span: 8,
          type: "select",
          clearable: true,
          dicData: [
            { label: "法人组织", value: "法人组织" },
            { label: "虚拟法人", value: "虚拟法人" },
            { label: "项目公司", value: "项目公司" },
            { label: "法人公司", value: "法人公司" },
            { label: "法人(分公司)", value: "法人(分公司)" },
            { label: "法人(参股公司)", value: "法人(参股公司)" },
          ],
          props: {
            label: "label",
            value: "value",
          },
          disabled: !["add", "edit"].includes(this.type),
          rules: [
            {
              required: true,
              message: "请选择类型名称",
              trigger: "change",
            },
          ],
        },
        {
          label: "上级名称",
          prop: "pcode",
          span: 8,
          dicData: [],
          type: "tree",
          hide: true,
          props: {
            label: "name",
            value: "code",
            children: "childList",
          },
          disabled: !["add", "edit"].includes(this.type),
        },
        {
          label: "省份/直辖市",
          prop: "provinceCode",
          span: 8,
          type: "select",
          props: {
            label: "name",
            value: "code",
          },
          cascader: ["cityCode"],
          dicUrl: "/api/sinoma-system/region/select?code=5651",
          disabled: !["add", "edit"].includes(this.type),
        },
        {
          label: "城市",
          prop: "cityCode",
          span: 8,
          type: "select",
          props: {
            label: "name",
            value: "code",
          },
          cascader: ["countyCode"],
          dicUrl: "/api/sinoma-system/region/select?code={{provinceCode}}",
          disabled: !["add", "edit"].includes(this.type),
        },
        {
          label: "区县",
          prop: "countyCode",
          span: 8,
          type: "select",
          props: {
            label: "name",
            value: "code",
          },
          dicUrl: "/api/sinoma-system/region/select?code={{cityCode}}",
          disabled: !["add", "edit"].includes(this.type),
        },
        {
          label: "境内外",
          prop: "projectAreaVals",
          span: 8,
          type: "select",
          clearable: true,
          dicData: [
            { label: "境内", value: "1" },
            { label: "境外", value: "2" },
          ],
          props: {
            label: "label",
            value: "value",
          },
          disabled: !["add", "edit"].includes(this.type),
          rules: [
            {
              required: true,
              message: "请选择境内境外",
              trigger: "change",
            },
          ],
        },
        {
          label: "分发类型",
          prop: "disType",
          type: "select",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=dis_type",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          rules: [
            {
              required: true,
              message: "请选择分发类型",
              trigger: "change",
            },
          ],
        },
        {
          label: "社会信用代码",
          prop: "creditCode",
          span: 8,
          maxlength: 200,
          showWordLimit: true,
          disabled: !["add", "edit"].includes(this.type),
        },
        {
          label: "联系电话",
          prop: "phone",
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["add", "edit"].includes(this.type),
          rules: [
            {
              required: false,
              validator: this.validatePhone,
              trigger: "blur",
            },
          ],
        },
        {
          label: "排序",
          prop: "sortOrder", //不是rank，数据库被改
          span: 8,
          type: "number",
          minRows: 0,
          maxRows: 1000000000,
          disabled: !["add", "edit"].includes(this.type),
        },
        // {
        //   label: "状态",
        //   prop: "status",
        //   type: "switch",
        //   value: 1,
        //   row: true,
        //   dataType: "number",
        //   // dicData: [
        //   //   { label: "启用", value: "1" },
        //   //   { label: "停用", value: "0" },
        //   // ],
        //   // props: {
        //   //   label: "label",
        //   //   value: "value",
        //   // },
        //   props: {
        //     label: "dictValue",
        //     value: "dictKey",
        //   },
        //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=dept_status",
        // },
        {
          label: "经营范围",
          prop: "businessScope",
          span: 24,
          type: "textarea",
          maxlength: 500,
          showWordLimit: true,
          disabled: !["add", "edit"].includes(this.type),
        },
      ];
    },
  },
  created() {
    // let { code, data, type } = this.$route.quary;
    let { code, data, type } = this.$route.query;
    console.log(this.$route.query, type, "cccccc");

    this.type = type;
    this.getDeptTree();
    if (this.type !== "add") {
      this.dataForm = data;
    }
  },
  mounted() {},
  methods: {
    // initData() {
    //   getList(this.dataForm.code).then((res) => {
    //     const { data } = res.data;
    //     this.dataForm = data;
    //   });
    // },
    getDeptTree() {
      this.submitLoading = true;
      tree("").then((res) => {
        const column = this.findObject(
          this.$refs.formLayout.option.column,
          "pcode"
        );
        column.dicData = res.data.data;
        this.submitLoading = false;
      });
    },
    headSave(cancel = false) {
      console.log(this.dataForm, "xxxxxxxxxxxxx"),
        (this.dataForm = this.$refs.formLayout.form.form);
      if (this.dataForm.pcode === this.dataForm.code) {
        return this.$message.warning(this.$t("cip.cmn.msg.error.nodeError"));
      }
      this.$refs.formLayout.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          this.dataForm.pname = this.dataForm.$pcode;
          submit(this.dataForm)
            .then((res) => {
              const { msg, data } = res.data;
              if (res.data.code === 200) {
                this.$message.success(
                  this.$t("cip.cmn.msg.success.operateSuccess")
                );
              } else {
                this.$message.success(msg);
              }
              if (cancel) {
                this.headCancel();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data);
                this.type = "edit";
                this.created();
                // this.initData();
              }
            })
            .finally(() => {
              this.$loading().close();
              this.$refs.formLayout.$refs.form.allDisabled = false;
            });
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 英文全称校验
    validateEnName(rule, value, callback) {
      if (value !== "") {
        let isOfficePhone = /^[A-Za-z\s]+$/;
        if (isOfficePhone.test(value)) {
          callback();
        } else {
          callback(new Error("只能输入英文"));
        }
      } else {
        callback();
      }
    },
    // 手机号校验
    validatePhone(rule, value, callback) {
      if (value !== "") {
        let isPhone = /^1[3-9]\d{9}$/;
        if (value.length === 11) {
          if (isPhone.test(value)) {
            callback();
          } else {
            callback(new Error("联系电话格式不正确"));
          }
        } else {
          callback(new Error("请输入联系电话长度为11位数字"));
        }
      } else {
        callback();
      }
    },
  },
};
</script>

<style scoped>
</style>
